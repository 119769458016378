import Register from "../components/Register/Register";
import configData from "../config.json";

const  AuthService = {

    login(username, password){

        let data = {
            'grant_type': 'password',
            'username': username,
            'password': password,
            'client_id': configData.CLIENTID,
            'client_secret': configData.CLIENTSECRET
        };
    
        let formBody = new URLSearchParams(Object.keys(data).map(key=>[key,data[key]]));
  
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: formBody
        };

      return  fetch(configData.API_URL + "/connect/token", requestOptions)
            .then(async response => {

                const data = await response.json();

                if (!response.ok) {
                  
                    const error = (data && data.error) || response.status;
                    return Promise.reject(error);
                }
    
                sessionStorage.setItem('token', data.access_token);
                return data;
            });

    },

    setToken(token) {
        sessionStorage.setItem('token', token);
    },

    getToken() {
        return sessionStorage.getItem('token') || null;
    },
    isAuthenticated() {
        
        if(sessionStorage.getItem('token') == null ){
            return false;
        }

        return true;
    },

    logout() {
        sessionStorage.removeItem('token');
    },

    register(data){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data) 
        };

      return  fetch(configData.API_URL + "/api/register", requestOptions)
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {

                if(response.status == 400){

                   let errorMsg = "";
                   for (const [key, value] of Object.entries(data.errors)) {
                    for (const [errorKey, errorValue] of Object.entries(value)) {
                           errorMsg+= key + " : " + errorValue + "<br/> ";
                        }
                  }
                    return Promise.reject(errorMsg);
                }
               
                const error = (data && data.error) || response.status;
                return Promise.reject(error);
            }
            return data;
        })

    }
  
}

export default  AuthService;