import React, { Component } from 'react';
import { Redirect  } from 'react-router-dom';
import '../../App.css';
import configData from "../../config.json";
import errorMessages from "../../errorMessages.json";
import authService from "../../services/AuthService.js";


class Register extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            error: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.dismissError = this.dismissError.bind(this);   
    }



    dismissError() {
        this.setState({ error: '' });
    }

    handleSubmit(evt) {
        evt.preventDefault();

        let data = {
            'email': this.state.email,
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'password': this.state.password,
            'confirmPassword': this.state.confirmPassword
        };

       
        authService.register(data)
        .then(async data => {

            authService.login(this.state.email,this.state.password)
            .then(async loginData => {

                this.props.history.push('/home');
            })
            .catch(loginErrorResponse => {

                this.setState({ error: loginErrorResponse });
            });          
        })
        .catch(errorResponse => {

            this.setState({ error: errorResponse });
        });


    }

 
    handleChange(evt) {
        this.setState({ [evt.target.id]: evt.target.value });
    }

    render() {


        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    {


                        this.state.error &&
                        <h3  onClick={this.dismissError}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.error
                                }}>

                            </div>

                        </h3>
                    }
                    <label for="email">Email</label>
                    <br></br>
                    <input type="text" id="email" name="email" value={this.state.username} onChange={this.handleChange} />
                    <br></br>
                    <label for="firstName">First Name</label>
                    <br></br>
                    <input type="text" id="firstName" name="firstName" value={this.state.firstName}onChange={this.handleChange}/>
                    <br></br>
                    <label for="lastName">Last Name</label>
                    <br></br>
                    <input type="text" id="lastName" name="lastName" value={this.state.username} onChange={this.handleChange}/>
                    <br></br>
                    <label for="password">Password</label>
                    <br></br>
                    <input type="password" id="password" name="password" value={this.state.password} onChange={this.handleChange}/>
                    <br></br>
                    <label for="confirmPassword">Confirm Password</label>
                    <br></br>
                    <input type="password" id="confirmPassword" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange}/>
                    <br></br>
                    <div >
                        <input type="submit" value="Create Account"/>
                    </div>
                </form>
            </div>
        );
    }
}

export default Register;