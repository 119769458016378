
import configData from "../config.json";

const  NewsService = {

    getNews() {


        if (sessionStorage.getItem('token') == null) {
          return null;
        }
    
        const requestOptions = {
          method: 'Get',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          }
        };
    
      return fetch(configData.API_URL + "/api/news", requestOptions)
          .then(async response => {
            const data = await response.json();
    
    
            if (response.status === 401) {
              
                // handle expire token try refresh etc
            }
    
            if (!response.ok) {
    
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
           
            return data;
          })
      }
    
}

export default  NewsService;