import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import Register from './components/Register/Register';





function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <ul className="nav justify-content-end">
          </ul>
          <div className="content">
            <Switch>
              <Route exact path="/" component={Login} />
              <PrivateRoute path="/home" component={Home} />
              <Route path="/register" component={Register} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
