import React, { Component } from 'react';
import authService from "../../services/AuthService.js";
import configData from "../../config.json";
import Logout from '../../components/Common/Logout';
import NewsService from '../../services/NewsService.js';


const students = [
  {
    'id': 1,
    'name': 'Student 1',
    'email': 'student1@mnp.com'
  },
  {
    'id': 2,
    'name': 'Student 2',
    'email': 'student2@mnp.com'
  },
  {
    'id': 2,
    'name': 'Student 3',
    'email': 'student3@mnp.com'
  },
];

class Home extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      articles: []
    };


  }

  componentDidMount() {

    this.getUser();
    this.getNews();
  }

  getUser() {


    if (sessionStorage.getItem('token') == null) {
      return null;
    }

    const requestOptions = {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      }
    };

    fetch(configData.API_URL + "/api/accounts", requestOptions)
      .then(async response => {
        const data = await response.json();


        if (response.status === 401) {
          alert("response");
        }

        if (!response.ok) {

          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
       
        this.setState({ name: data.firstName });
      })
      .catch(error => {

        //adding logging of error.
        console.error('There has been a problem with your fetch operation:', error);

      })
  }


  getNews() {


    NewsService.getNews()
      .then(async data => {
     
        this.setState({ articles: data });
      })
      .catch(errorResponse => {

        //adding logging of error.

      })
  }

  render() {
    return (
      <div className="container" style={{ 'display': 'table', marginTop: "50px" }}>
        <div className="row">
          <div className="col-3 border border-dark">
            <img src="https://developer.nytimes.com/files/poweredby_nytimes_200c.png?v=1583354208354"></img>
          </div>
          <div className="col-6 border border-dark" >
            {this.state.articles.map((article, index) => (
              <div className="card rounded" style={{ marginBottom: '25px' }} >
                <a href={article.articleURL}>
                  <img className="card-img-top" src={article.headlineImageURL} alt="Card image cap" />
                </a>
                <div className="card-body">
                  <h5 className="card-title">{article.title}</h5>
                  <p className="card-text">{article.author}</p>
                </div>
              </div>

            ))}

          </div>
          <div className="col-3 border border-dark" >
            <h5>Hi {this.state.name} </h5>
            <Logout/>

          </div>
        </div>
      </div>



    );
  }

}


export default Home;