import { Button } from 'bootstrap';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../../App.css';
import configData from "../../config.json";
import errorMessages from "../../errorMessages.json";
import authService from "../../services/AuthService.js";


class Logout extends Component {
    constructor() {
        super();
        this.state = {
            logout: false,

        };
    }


    logout = () => {
        authService.logout();
        this.setState({ logout: true });
    };



    render() {

        const { logout } = this.state

        if (logout) {

            return <Redirect to="/" />;
        }

        if (authService.isAuthenticated()) {
            return (
                <a onClick={this.logout}>
                    Logout
                </a>
            );
        }

        return null;
    }
}

export default Logout;