import React, { Component } from 'react';
import { Redirect  } from 'react-router-dom';
import '../../App.css';
import configData from "../../config.json";
import errorMessages from "../../errorMessages.json";
import authService from "../../services/AuthService.js";


class Login extends Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            error: '',
        };

        this.handleChange = this.handleChange.bind(this);  
        this.handleSubmit = this.handleSubmit.bind(this);
        this.dismissError = this.dismissError.bind(this);   
        this.handleRegisterPressed = this.handleRegisterPressed.bind(this);  
    }



    dismissError() {
        this.setState({ error: '' });
    }

    handleSubmit(evt) {
        evt.preventDefault();

        let msg = '';

        if (!this.state.username) {

            msg += 'Username is required <br/> ';
        }

        if (!this.state.password) {
            msg += 'Password is required <br/>';
        }

        if (msg !== '') {

            return this.setState({ error: msg });
        }

    
      authService.login(this.state.username,this.state.password)
        .then(async data => {


            this.props.history.push('/home');
            
        })
        .catch(errorResponse => {

            this.setState({ error: errorMessages.Login.Failed });
        });


    }

    handleRegisterPressed(evt){
        this.props.history.push('/register');

    }

    handleChange(evt) {
        this.setState({ [evt.target.id]: evt.target.value });
    }
    render() {

        if (authService.isAuthenticated()) {
            return <Redirect to="/home" />;
          }

        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    {


                        this.state.error &&
                        <h3  onClick={this.dismissError}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.error
                                }}>

                            </div>

                        </h3>
                    }
                    <label for="username">Email</label>
                    <br></br>
                    <input type="text" id="username" name="username" value={this.state.username} onChange={this.handleChange} />
                    <br></br>
                    <label for="password">Password</label>
                    <br></br>
                    <input type="password" id="password" name="password" value={this.state.password} onChange={this.handleChange} />
                    <br></br>
                    <div >
                        <input type="submit" value="Log In" />
                        <input type="submit" value="Register"  onClick={this.handleRegisterPressed} />
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;